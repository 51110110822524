import { QuestionType } from '@shared/QuestionType';
import { IQuestionResponse } from '@shared/question_response';
import { IQuestion } from '@shared/questions';
import { DisplayDirection } from '@shared/rating_scales';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { EditedLabel } from '@web/components/EditedLabel';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3 } from '@web/components/typography';
import { reverse, sortBy } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { renderReceiverQuestionText } from './renderReceiverQuestionText';

export const QuestionResponses: React.FC<{
  questions: IQuestion[];
  responses?: IQuestionResponse[];
  receiverName?: string;
  hideNullResponses?: boolean;
}> = ({ responses, questions, receiverName, hideNullResponses = false }) => {
  if (!responses) {
    return null;
  }

  const answeredQuestions = questions.filter((q) =>
    responses?.some((qr) => qr.questionToken === q.token),
  );
  return (
    <Column gap={24}>
      {answeredQuestions.map((question) => (
        <QuestionResponse
          key={question.token}
          question={question}
          receiverName={receiverName}
          hideNullResponses={hideNullResponses}
          response={responses?.find(
            (response) => response.questionToken === question.token,
          )}
        />
      ))}
    </Column>
  );
};

export const QuestionResponse: React.FC<{
  question: IQuestion;
  response?: IQuestionResponse;
  receiverName?: string;
  hideNullResponses?: boolean;
}> = ({ question, response, receiverName, hideNullResponses = false }) => {
  if (!response) {
    return null;
  } else if (hideNullResponses) {
    if (question.type === QuestionType.OPEN_ENDED && response.text === null) {
      return null;
    } else if (
      question.type === QuestionType.RATING &&
      response.rating === null
    ) {
      return null;
    }
  }

  let responseSummary = <Text>{response?.text}</Text>;
  switch (question.type) {
    case QuestionType.RATING: {
      let scale = sortBy(question.scale ?? [], 'value');
      if (question.displayDirection === DisplayDirection.DESCENDING) {
        scale = reverse(scale);
      }
      responseSummary = (
        <Column>
          {scale.map((rating) => (
            <RatingResponseRow
              key={`rating_option_${rating.value}`}
              selected={response?.rating === rating.value}
            >
              <Text
                style={
                  response?.rating === rating.value
                    ? { color: complimentaryColor.darken(0.5) }
                    : undefined
                }
              >
                {rating.value} - {rating.label}
              </Text>
            </RatingResponseRow>
          ))}
        </Column>
      );
      break;
    }
    case QuestionType.OPEN_ENDED:
    default:
      responseSummary = (
        <Text style={{ whiteSpace: 'pre-wrap' }}>{response?.text}</Text>
      );
  }

  return (
    <Column gap={6}>
      <Header3 style={{ whiteSpace: 'pre-wrap' }}>
        {renderReceiverQuestionText(question, receiverName)}
      </Header3>
      {responseSummary}
      <EditedLabel
        entity={{
          createdDate: response.createdDate,
          updatedDate: response.updatedDate,
        }}
      />
    </Column>
  );
};

const RatingResponseRow = styled(Row)<{ selected?: boolean }>`
  padding: 6px 12px;
  gap: 6px;
  border: 1px solid transparent;

  ${(props) =>
    props.selected
      ? `background-color: ${complimentaryColor.lighten(
          0.9,
        )}; border-color: ${complimentaryColor.lighten(0.6)};`
      : 'unset'}
  border-radius: var(--default-border-radius);
  max-width: 500px;
`;
