import { ISurveyCycle } from '@shared/surveys';
import { BorderedTabLink, BorderedTabs } from '@web/components/BorderedTabs';
import * as React from 'react';

export const AdminSurveyTabs: React.FC<{ surveyCycle: ISurveyCycle }> = ({
  surveyCycle,
}) => {
  const surveyCyclePath = `/admin/surveys/${surveyCycle?.token}`;

  return (
    <BorderedTabs>
      <BorderedTabLink to={`${surveyCyclePath}/settings`}>
        Settings
      </BorderedTabLink>
      <BorderedTabLink to={`${surveyCyclePath}/sections`}>
        Sections
      </BorderedTabLink>
      <BorderedTabLink to={`${surveyCyclePath}/participants`}>
        Participants
      </BorderedTabLink>
      {surveyCycle?.startedDate && !surveyCycle.anonymous && (
        <BorderedTabLink to={`${surveyCyclePath}/teams`}>Teams</BorderedTabLink>
      )}
      {surveyCycle?.startedDate && (
        <BorderedTabLink to={`${surveyCyclePath}/responses`}>
          Responses
        </BorderedTabLink>
      )}
    </BorderedTabs>
  );
};
