import { SearchOutlined } from '@ant-design/icons';
import { useAdminTeams } from '@client/SurveyCyclesClient';
import { ISurveyCycleTeamSummary, SurveyCycleToken } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { useAuth } from '@web/auth/useAuth';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Row } from '@web/components/layout';
import { Small } from '@web/components/typography';
import { Button, Empty, Progress, Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { filter } from 'lodash';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { AdminSurveyContent } from './AdminSurveyContent';

const DEFAULT_COLUMN_WIDTH = '150px';

export const AdminSurveyCycleTeamsPage: React.FC = () => {
  const { isHrAdmin } = useAuth();
  const { surveyCycleToken } = useParams<{
    surveyCycleToken?: SurveyCycleToken;
  }>();
  const { data: response } = useAdminTeams(surveyCycleToken as any);
  if (!response) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const rows = Object.values(response.teamSummaries);
  const columns: ColumnsType<ISurveyCycleTeamSummary> = [
    {
      title: 'Team Manager',
      dataIndex: 'team',
      key: 'manager',
      render: (team) => {
        return team.manager ? (
          <Row gap={6}>
            <UserMessage
              user={team.manager}
              style={{ whiteSpace: 'nowrap' }}
              width="auto"
              avatarSize={24}
            />
            {isHrAdmin && (
              <Tooltip title={`View team's survey responses`}>
                <Link
                  to={`/surveys/${surveyCycleToken}/team/${team.manager.token}`}
                >
                  <Button type="text" size="small">
                    <SearchOutlined />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </Row>
        ) : (
          'N/A'
        );
      },
    },
    {
      title: 'Completed',
      key: 'completed',
      align: 'center',
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, row) => (
        <ProgressCell
          count={row.totalCompleted}
          total={row.totalParticipants}
        />
      ),
    },
  ];

  return (
    <AdminSurveyContent surveyCycle={response.surveyCycle}>
      <Column>
        <Table<ISurveyCycleTeamSummary>
          columns={filter(columns)}
          dataSource={rows}
          locale={{
            emptyText: <Empty description="No teams found" />,
          }}
          rowKey="token"
          tableLayout="auto"
          sticky={true}
          scroll={{ x: true }}
        />
      </Column>
    </AdminSurveyContent>
  );
};

const ProgressCell: React.FC<{ count: number; total: number }> = ({
  count,
  total,
}) => {
  const percent = Math.round((count / total) * 100);
  return (
    <Row style={{ justifyContent: 'center' }} gap={12}>
      <Progress
        showInfo={false}
        style={{ margin: 0, width: 35 }}
        percent={percent}
      />
      <Small style={{ whiteSpace: 'nowrap' }}>
        {count}/{total}
      </Small>
    </Row>
  );
};
