import { InfoCircleOutlined } from '@ant-design/icons';
import { addSurveySection } from '@client/SurveyCyclesClient';
import {
  QuestionType,
  QuestionTypeLabels,
  isQuestionType,
} from '@shared/QuestionType';
import { Feature } from '@shared/features';
import {
  IQuestion,
  IRatingQuestion,
  QuestionToken,
  isRatingQuestion,
} from '@shared/questions';
import {
  DisplayDirection,
  FavorabilityDirection,
  RatingScale,
} from '@shared/rating_scales';
import {
  ISurveyCycle,
  SurveySectionType,
  SurveyType,
  isSurveySectionType,
} from '@shared/surveys';
import { patch, post } from '@web/common/api';
import { useFeature } from '@web/common/useFeature';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { CollapsableSection } from '@web/goals/CollapsableSection';
import {
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { sortBy } from 'lodash';
import React, { useState } from 'react';

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
  open: boolean;
  surveyCycle: ISurveyCycle;
  question?: IQuestion;
  readonly?: boolean;
  isNew: boolean;
  section?: QuestionToken | SurveySectionType;
  blacklistSectionTypes?: SurveySectionType[];
}

const SectionOptionLabels = {
  [QuestionType.RATING]: `${QuestionTypeLabels[QuestionType.RATING]} question`,
  [QuestionType.OPEN_ENDED]: `${
    QuestionTypeLabels[QuestionType.OPEN_ENDED]
  } question`,
  [QuestionType.LABEL]: QuestionTypeLabels[QuestionType.LABEL],
  [SurveySectionType.ONE_ON_ONE_PREPAREDNESS]: `1-on-1 preparedness`,
};

export const EditSectionModal: React.FC<Props> = ({
  onUpdate,
  onCancel,
  open,
  surveyCycle,
  question,
  readonly,
  isNew,
  section,
  blacklistSectionTypes = [],
}) => {
  const [form] = Form.useForm<ISectionForm>();
  const [type, setType] = useState(
    isSurveySectionType(section)
      ? section
      : question?.type ?? QuestionType.RATING,
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [required, setRequired] = React.useState(
    question ? question.required : false,
  );
  const [displayDirection, setDisplayDirection] = React.useState(
    question &&
      question.type === QuestionType.RATING &&
      question.displayDirection
      ? question.displayDirection
      : DisplayDirection.ASCENDING,
  );
  const [favorabilityDirection, setFavorabilityDirection] = React.useState(
    question &&
      question.type === QuestionType.RATING &&
      question.favorabilityDirection
      ? question.favorabilityDirection
      : FavorabilityDirection.ASCENDING,
  );
  const { booleanValue: enableEngagementResults } = useFeature(
    Feature.ENABLE_ENGAGEMENT_RESULTS,
  );

  const handleOk = async () => {
    setIsSaving(true);
    let sectionForm: ISectionForm;
    try {
      sectionForm = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      if (isQuestionType(type)) {
        const questionAttributes: Partial<IQuestion> = {
          text: sectionForm.text,
          type,
          scale: createScale(type, sectionForm),
          required,
          displayDirection:
            type === QuestionType.RATING ? displayDirection : undefined,
          favorabilityDirection:
            type === QuestionType.RATING ? favorabilityDirection : undefined,
        };
        if (!isNew && question) {
          await patch<IQuestion>(
            `/survey-cycles/${surveyCycle.token}/questions/${question.token}`,
            questionAttributes,
          );
        } else {
          await post<IQuestion>(
            `/survey-cycles/${surveyCycle.token}/questions`,
            questionAttributes,
          );
        }
      } else if (type === SurveySectionType.ONE_ON_ONE_PREPAREDNESS) {
        await addSurveySection(surveyCycle.token, type);
      } else {
        void message.error('Something went wrong');
        return;
      }

      void message.success('Success');
      form.resetFields();
      onUpdate();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  const initialQuestionFormValues: Partial<ISectionForm> = !isNew
    ? {
        text: question?.text,
        ...flattenScale(question as IRatingQuestion),
      }
    : {};

  let allowedSections: Array<QuestionType | SurveySectionType> = [
    QuestionType.RATING,
    QuestionType.OPEN_ENDED,
    QuestionType.LABEL,
  ];
  if (surveyCycle.type === SurveyType.CHECK_IN) {
    allowedSections.push(SurveySectionType.ONE_ON_ONE_PREPAREDNESS);
  }
  allowedSections = allowedSections.filter(
    (section) => !blacklistSectionTypes.includes(section as any),
  );

  return (
    <Modal
      title={
        readonly ? 'View Section' : !isNew ? 'Edit Section' : 'Add Section'
      }
      open={open}
      onOk={() => {
        void handleOk();
      }}
      okButtonProps={{
        disabled:
          readonly &&
          (question?.type !== QuestionType.RATING ||
            (displayDirection === question?.displayDirection &&
              favorabilityDirection === question?.favorabilityDirection)),
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="600px"
    >
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        initialValues={initialQuestionFormValues}
      >
        {isNew ? (
          <Select
            disabled={isSaving}
            value={type}
            onChange={(newType) => {
              setType(newType);
            }}
            style={{ width: 200 }}
          >
            {allowedSections.map((section) => (
              <Select.Option key={section} value={section}>
                {SectionOptionLabels[section]}
              </Select.Option>
            ))}
          </Select>
        ) : type ? (
          <Text style={{ fontWeight: 500 }}>{SectionOptionLabels[type]}</Text>
        ) : null}
        <Spacer size={18} />
        {isQuestionType(type) && (
          <Form.Item
            label="Question Text"
            name="text"
            rules={[{ required: true, message: 'Text is required' }]}
          >
            <TextArea autoSize disabled={isSaving || readonly} autoFocus />
          </Form.Item>
        )}
        {type === QuestionType.RATING && (
          <>
            <Typography.Title level={5}>Response Options</Typography.Title>
            <Form.Item
              name="scaleLabel1"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="1." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item
              name="scaleLabel2"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="2." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item
              name="scaleLabel3"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input prefix="3." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item name="scaleLabel4">
              <Input prefix="4." disabled={isSaving || readonly} />
            </Form.Item>
            <Form.Item name="scaleLabel5">
              <Input prefix="5." disabled={isSaving || readonly} />
            </Form.Item>
          </>
        )}
        {![
          QuestionType.LABEL,
          SurveySectionType.ONE_ON_ONE_PREPAREDNESS,
        ].includes(type) ? (
          <Row gap={6}>
            <Checkbox
              checked={required}
              onChange={() => {
                setRequired(!required);
              }}
              disabled={readonly}
            />
            <Text>Required</Text>
          </Row>
        ) : undefined}
        {type === QuestionType.RATING && (
          <>
            <Spacer size={12} />
            <CollapsableSection
              title={`Additional Configuration`}
              startExpanded={
                displayDirection === DisplayDirection.DESCENDING ||
                favorabilityDirection === FavorabilityDirection.DESCENDING
              }
            >
              <Spacer size={12} />
              <Column gap={6}>
                <Text>
                  {`Display Direction `}
                  <Tooltip title="When displaying the response options, this setting determines which order to use.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Text>
                <Select
                  disabled={isSaving}
                  value={displayDirection}
                  onChange={(newDisplayDirection) => {
                    setDisplayDirection(newDisplayDirection);
                  }}
                >
                  <Select.Option value={DisplayDirection.ASCENDING}>
                    Ascending order (1 is displayed first)
                  </Select.Option>
                  <Select.Option value={DisplayDirection.DESCENDING}>
                    Descending order (1 is displayed last)
                  </Select.Option>
                </Select>
              </Column>
              {enableEngagementResults && (
                <>
                  <Spacer size={12} />
                  <Column gap={6}>
                    <Text>
                      {`Favorability Direction `}
                      <Tooltip title="When analyzing exported results, this setting determines which response options are considered favorable.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Text>
                    <Select
                      disabled={isSaving}
                      value={favorabilityDirection}
                      onChange={(newFavorabilityDirection) => {
                        setFavorabilityDirection(newFavorabilityDirection);
                      }}
                    >
                      <Select.Option value={FavorabilityDirection.ASCENDING}>
                        Ascending order (1 is most favorable)
                      </Select.Option>
                      <Select.Option value={FavorabilityDirection.DESCENDING}>
                        Descending order (1 is least favorable)
                      </Select.Option>
                    </Select>
                  </Column>
                </>
              )}
              <Spacer size={12} />
            </CollapsableSection>
          </>
        )}

        {type === SurveySectionType.ONE_ON_ONE_PREPAREDNESS && (
          <Text>
            Empower employees to prepare upcoming 1-on-1 with their manager by
            resolving agenda / action items and creating any new items to be
            discussed.
          </Text>
        )}
      </Form>
    </Modal>
  );
};

interface ISectionForm {
  text: string;
  scaleLabel1?: string;
  scaleLabel2?: string;
  scaleLabel3?: string;
  scaleLabel4?: string;
  scaleLabel5?: string;
  noLabel?: string;
  yesLabel?: string;
}

const flattenScale = (question: IRatingQuestion) => {
  const sortedScale = sortBy(question?.scale ?? [], 'value');
  if (isRatingQuestion(question)) {
    return {
      scaleLabel1: sortedScale[0]?.label,
      scaleLabel2: sortedScale[1]?.label,
      scaleLabel3: sortedScale[2]?.label,
      scaleLabel4: sortedScale[3]?.label,
      scaleLabel5: sortedScale[4]?.label,
    };
  }
  return {};
};

const createScale = (
  type: QuestionType,
  sectionForm: ISectionForm,
): RatingScale | undefined => {
  if (type !== QuestionType.RATING) {
    return undefined;
  }

  const scale: RatingScale = [];
  if (sectionForm.scaleLabel1) {
    scale.push({
      value: scale.length + 1,
      label: sectionForm.scaleLabel1,
    });
  }
  if (sectionForm.scaleLabel2) {
    scale.push({
      value: scale.length + 1,
      label: sectionForm.scaleLabel2,
    });
  }
  if (sectionForm.scaleLabel3) {
    scale.push({
      value: scale.length + 1,
      label: sectionForm.scaleLabel3,
    });
  }
  if (sectionForm.scaleLabel4) {
    scale.push({
      value: scale.length + 1,
      label: sectionForm.scaleLabel4,
    });
  }
  if (sectionForm.scaleLabel5) {
    scale.push({
      value: scale.length + 1,
      label: sectionForm.scaleLabel5,
    });
  }
  return scale;
};
